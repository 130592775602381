import React, {useEffect, useState} from 'react';
import SubmitButton from "../pwd/SubmitButton";
import {useAppContext} from "../../../../context/Context";
import api from "../../../../utils/api";
import TitleAndSubTitle from "../TitleAndSubTitle";
import { checkEmail, setBottomBarError, setBottomBarProcessing, setBottomBarSucces } from '../../../../utils/Functions';
import { ACTION } from '../../../../context/actionTypes';
import { useNavigate } from 'react-router-dom';
import { InputComponent } from '../../../../utils/FormComponents';



/** Main component for changing the personal Info
 *
 * @constructor
 */
const ChangePersonalInfo: React.FC = () => {
    const [{ user },dispatch] = useAppContext()
    const [currentEmail, setcurrentEmail] = useState("");
    const [firstName, setfirstName] = useState("")
    const [lastName, setlastName] = useState("")
    const [password, setpassword] = useState("");
    const [errors, setErrors] = useState<Record<string, string>>({});

    const handleChangeInfo = async (event) => {
        event.preventDefault();
        // Check for errors in input
        let curErrors: Record<string, string> = {};
        try {
            if (firstName.trim()==="") curErrors.first_name = "Le prénom est obligatoire";
            if (!checkEmail(currentEmail)) curErrors.email = "L'email est invalide";
            if (lastName.trim()==="") curErrors.last_name = "Le nom est obligatoire";
            if (password === "") curErrors.password = "Le mot de passe est obligatoire";
            setErrors(curErrors);
            if (Object.keys(curErrors).length > 0) return;
        //Send data to api
            const newUserResponse=await api.patch(`/user/${user.id}`,{first_name:firstName,last_name:lastName,email:currentEmail,password:password});
            const newUser = newUserResponse.data;

            if (newUser.email === currentEmail) { // If the email is not changed, then just notify the update of the user info
                setBottomBarSucces(dispatch, "Vos informations ont été mis à jour avec succès !");
            } else if (firstName === user.first_name && lastName === user.last_name) { // If the email is changed, but not the first_name or last_name, ask the user to check the link sent to the update email
                setBottomBarProcessing(dispatch, `Un code de vérification a été envoyé à ${currentEmail} afin de valider cette nouvelle adresse.`);
            } else { // If the email is changed, and the first_name or last_name or both, notify the update of the user info and ask them to check the link sent to the update email
                setBottomBarProcessing(dispatch, `Votre nom et/ou prénom ont été modifiés.\nUn code de vérification a été envoyé à ${currentEmail} afin de valider cette nouvelle adresse.`);
            }
            dispatch({
                type: ACTION.LOAD_SESSION,
                payload: { user: newUser }
            });
        } catch (error) {
            console.error(`Could not update user`, error);
            setBottomBarError(dispatch, error);
        }

    };
    
    useEffect(() => {
        //Check if user id defined before intialising state variables
        if (user){
        setcurrentEmail(user.email)
        setfirstName(user.first_name)
        setlastName(user.last_name)}
    }, [user])
    

    return (

        <main className="change-pwd">
            <TitleAndSubTitle h1="Gestion des informations personnelles" h2="Mettre à jour vos informations personnelles"/>

            <hr/>
            <form onSubmit={handleChangeInfo} autoComplete="off">
                    <InputComponent
                        label="Email"
                        name="email"
                        value={currentEmail}
                        onChange={e=>setcurrentEmail(e.target.value)}
                        error={errors.email}
                    />
                <InputComponent
                        label="Prénom"
                        name="first_name"
                        value={firstName}
                        onChange={e=>setfirstName(e.target.value)}
                        error={errors.first_name}
                    />
                <InputComponent
                        label="Nom"
                        name="last_name"
                        value={lastName}
                        onChange={e=>setlastName(e.target.value)}
                        error={errors.last_name}
                    />
                <InputComponent
                    label="Mot de passe actuel"
                    name="password"
                    type="password"
                    value={password}
                    onChange={e=>setpassword(e.target.value)}
                    error={errors.password}
                />
                <SubmitButton text="Valider"/>
            </form>

        </main>
    )
        ;
}
export default ChangePersonalInfo;