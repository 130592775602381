import React from 'react';
import './Settings.css';
import {Routes, Route, NavLink, Navigate} from 'react-router-dom';
import Home from "./home/Home";
import SignIn from "../SignIn"; // Import the CSS file
import ChangePassword from "./pwd/ChangePassword";
import TitleAndSubTitle from "./TitleAndSubTitle";
import ResetPassword from "./pwd/ResetPassword";
import {twMerge} from "tailwind-merge";
import SalesRep from "./salesrep/SalesRep";
import IntroductionMessage from './introductionMessage/IntroductionMessage';
import Changelog from "./changelog/Changelog";
import {useAppContext} from "../../../context/Context";
import CompanyAdministration from "./companyAdministration/CompanyAdministration";
import ApiKeys from "./api_keys/ApiKeys";
import ChangePersonalInfo from './personal_info/ChangePersonalInfo';


const Settings: React.FC = () => {
    const [{ isAdminVirteem, isAdmin },] = useAppContext();

    return (
        <main className="main-settings">
            <TitleAndSubTitle h1="Paramètres" h2="Gérez les paramètres de votre compte"/>
            <div className="settings-container">
                {/* Navigation menu with links to Home, Change Password, Reset Password, and Profile pages */}
                <nav className="settings-nav">
                    <ul>
                        <li>
                            <h2>Utilisateur</h2>
                        </li>
                        <li>
                            <NavLink to="home"
                                     className={twMerge(`${({isActive}) => (isActive ? 'active' : '')} w-full`)}
                                     end>Accueil</NavLink> {/* Link to Home page */}
                        </li>
                        <li>
                            <NavLink to="personal_info"
                                     className={twMerge(`${({isActive}) => (isActive ? 'active' : '')} w-full`)}
                            >Gestion des informations personnelles</NavLink> {/* Link to Home page */}
                        </li>
                        <li>
                            <NavLink to="security"
                                     className={twMerge(`${({isActive}) => (isActive ? 'active' : '')} w-full`)}
                            >Gestion du mot de passe</NavLink> {/* Link to Home page */}
                        </li>
                        <li>
                            <NavLink to="reset"
                                     className={twMerge(`${({isActive}) => (isActive ? 'active' : '')} w-full`)}
                            >Récupération</NavLink> {/* Link to Home page */}
                        </li>
                        {isAdmin && (
                            <>
                                <hr/>
                                <li>
                                    <h2>Entreprise</h2>
                                </li>
                                <li>
                                    <NavLink to="salesrep"
                                             className={twMerge(`${({isActive}) => (isActive ? 'active' : '')} w-full`)}
                                    >Référent</NavLink> {/* Link to Home page */}
                                </li>
                                <li>
                                    <NavLink to="introduction_message"
                                             className={twMerge(`${({isActive}) => (isActive ? 'active' : '')} w-full`)}
                                    >Message d'introduction</NavLink> {/* Link to Home page */}
                                </li>
                                <li>
                                    <NavLink to="company-administration"
                                             className={twMerge(`${({isActive}) => (isActive ? 'active' : '')} w-full`)}
                                    >Administration de l'entreprise</NavLink> {/* Link to Home page */}
                                </li>
                                {isAdminVirteem && (
                                    <li>
                                        <NavLink to="api-keys"
                                                 className={twMerge(`${({isActive}) => (isActive ? 'active' : '')} w-full`)}
                                        >Clés d'API</NavLink>
                                    </li>
                                )}
                            </>
                        )}
                        {isAdminVirteem && (
                            <>
                                <hr/>
                                <li>
                                    <NavLink to="changelog"
                                             className={twMerge(`${({isActive}) => (isActive ? 'active' : '')} w-full`)}
                                    >Nouvelle version</NavLink>
                                </li>
                            </>
                        )}
                    </ul>
                </nav>

                {/* Define routes for the settings section */}
                <div className="settings-content">
                    <Routes>
                        {/* eslint-disable-next-line react/jsx-no-undef */}

                        <Route path="home" element={<Home/>}/> {/* Route for Home component */}
                        {/* eslint-disable-next-line react/jsx-no-undef */}
                        <Route path="security" element={<ChangePassword/>}/> {/* Route for change password component */}
                        <Route path="personal_info" element={<ChangePersonalInfo/>}/> {/* Route for change password component */}
                        <Route path="reset" element={<ResetPassword/>}/> {/* Route for change password component */}
                        <Route path="salesrep" element={<SalesRep/>}/>
                        <Route path="introduction_message" element={<IntroductionMessage/>}/>
                        <Route path="company-administration" element={<CompanyAdministration/>}/>
                        <Route path="api-keys" element={<ApiKeys/>}></Route>
                        <Route path="changelog" element={<Changelog/>}/> {/* Route for changelog component */}
                        <Route path="/" element={<Navigate to="/administration/settings/home" />}/> {/* Default route */}
                    </Routes>
                </div>
                <SignIn/>
            </div>
        </main>
    );
}
export default Settings;