import { useEffect } from "react";
import api from "../../../../utils/api";
import { useNavigate, useParams } from 'react-router-dom';
import { setBottomBarError, setBottomBarSucces } from "../../../../utils/Functions";
import { useAppContext } from "../../../../context/Context";
import {ACTION} from "../../../../context/actionTypes";
export default function ValidatePersonalInfoCode() {
    //Fake page that sends to the home page, used to trigger a BottomBar to validate an email change link
    const [, dispatch] = useAppContext()
    const { code, email } = useParams();
    const navigate = useNavigate();

    const validateCode = async (code,email) => {
        try {
            const response = await api.get(`/user/verify/${code}/${email}`);
            const newUser = await response.data;
            setBottomBarSucces(dispatch,"Vos informations ont été mis à jour avec succès !");
            dispatch({
                type: ACTION.LOAD_SESSION,
                payload: { user: newUser }
            });
        } catch (error){
            console.error(`Could not update user`, error);
            setBottomBarError(dispatch, error);
        }
    }
    useEffect(() => {
        navigate('/administration/settings/personal_info', { replace: true });

        validateCode(code,email);
    }, []);

    return (
        <></>
    );
}
